import React, { useState, useEffect } from 'react';
import Notify from '../components/global/notify';
import { APIPost } from '../utils/apicore';
import { toast } from 'react-toastify';

function SignUp() {
	useEffect(() => {
		try {
			document.body.classList.add('authentication-bg', 'authentication-bg-pattern');
		} catch (error) {
			console.error('Error adding classes to body:', error);
		}
	}, []);

	const [name, setName] = useState('');
	const [username, setUsername] = useState('');
	const [id_number, setId_number] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [w_pin, setW_pin] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);

	const handleChange = (event) => {
		switch (event.target.id) {
			case 'name':
				setName(event.target.value);
				break;
			case 'id_number':
				setId_number(event.target.value);
				break;
			case 'username':
				setUsername(event.target.value);
				break;
			case 'email':
				setEmail(event.target.value);
				break;
			case 'phoneNumber':
				setPhoneNumber(event.target.value);
				break;
			case 'w_pin':
				setW_pin(event.target.value);
				break;
			case 'checkbox-signup':
				setTermsAccepted(event.target.checked);
				break;
			default:
				break;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let errorMessage = '';

		if (!/^\S+@\S+\.\S+$/.test(email)) {
			errorMessage += 'Invalid email address';
		}

		// PIN validation
		if (w_pin.length !== 4 || !/^\d+$/.test(w_pin)) {
			errorMessage += 'Invalid PIN. Must be 4 digits long';
		}

		// Terms validation
		if (!termsAccepted) {
			errorMessage += 'Please accept the terms and conditions';
		}

		// check all inputs are filled
		if (name === '' || id_number === '' || username === '' || email === '' || phoneNumber === '' || w_pin === '') {
			errorMessage += 'Please fill all fields';
		}

		if (errorMessage !== '') {
			toast.dismiss();
			toast.error(errorMessage);
			return;
		}

		// send to the API
		const body = {
			name,
			id_number,
			username,
			email,
			phoneNumber,
			w_pin,
		};

		const result = await APIPost('users/register', body, null);
		console.log('Results:', result);
		if (!result.success) {
			toast.dismiss();
			toast.error(result.message);
		}

		toast.dismiss();
		toast.success('Registration Successful');
	};

	return (
		<>
			<Notify />

			<div className='account-pages mt-5 mb-5'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-4'>
							<div className='text-center'>
								<a href='/'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='50'
										className='mx-auto'
									/>
								</a>
								<p className='text-muted mt-2 mb-4'>Loyalty Management System</p>
							</div>
							<div className='card'>
								<div className='card-body p-4'>
									<div className='text-center mb-4'>
										<h4 className='text-uppercase mt-0'>Register</h4>
									</div>

									<form action='#'>
										<div className='mb-3'>
											<label className='form-label'>Full Name</label>
											<input
												className='form-control'
												type='text'
												id='name'
												placeholder='Enter your full name'
												required
												value={name}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Username</label>
											<input
												className='form-control'
												type='text'
												id='username'
												placeholder='What do you want to be called?'
												required
												value={username}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>ID Number</label>
											<input
												className='form-control'
												type='text'
												id='id_number'
												placeholder='Enter your ID Number'
												required
												value={id_number}
												onChange={handleChange}
											/>
										</div>

										<div className='mb-3'>
											<label className='form-label'>Email address</label>
											<input
												className='form-control'
												type='email'
												id='email'
												required
												placeholder='Enter your email'
												value={email}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>Phone Number</label>
											<input
												className='form-control'
												type='tel'
												id='phoneNumber'
												required
												placeholder='Enter your phoneNumber (+.....)'
												value={phoneNumber}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<label className='form-label'>PIN</label>
											<input
												className='form-control'
												type='password'
												required
												id='w_pin'
												placeholder='Enter your 4 Digit PIN'
												value={w_pin}
												onChange={handleChange}
											/>
										</div>
										<div className='mb-3'>
											<div className='form-check'>
												<input
													type='checkbox'
													className='form-check-input'
													id='checkbox-signup'
													checked={termsAccepted}
													onChange={handleChange}
												/>
												<label className='form-check-label'>
													I accept{' '}
													<a
														href='/#/terms'
														className='text-dark'
													>
														Terms and Conditions
													</a>
												</label>
											</div>
										</div>
										<div className='mb-3 text-center d-grid'>
											<button
												className='btn btn-primary'
												type='submit'
												onClick={(e) => handleSubmit(e)}
											>
												{' '}
												Sign Up{' '}
											</button>
										</div>
									</form>
								</div>
							</div>

							<div className='row mt-3'>
								<div className='col-12 text-center'>
									<p className='text-dark'>
										Already have account?{' '}
										<a
											href='/#/login'
											className='text-dark ms-1'
										>
											<b>Sign In</b>
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SignUp;
