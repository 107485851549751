import { useEffect, useState } from 'react';
import { APIPost } from '../utils/apicore';
import Notify from '../components/global/notify';
import axios from 'axios';
import { toast } from 'react-toastify';

const LoadCard = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [amount, setAmount] = useState(0.0);
	const [token, setToken] = useState('');
	const [merchant, setMerchant] = useState({});

	useEffect(() => {
		// get the token from local storage
		const token = localStorage.getItem('token');

		// get the merchant from local storage
		const merchant = JSON.parse(localStorage.getItem('merchant'));

		// if token is not found, redirect to login
		if (!token || token === '' || token === null || !merchant) {
			window.location.href = '/#/login';
		}

		// get the user's country location
		const getUserCountry = async () => {
			try {
				const response = await axios.get(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IP_APIKEY}`);

				// set the user's country code
				setCountryCode(`+${response.data.calling_code}`);
				// console.log(response.data);
				// console.log('Calling Code:', response.data.calling_code);
			} catch (error) {
				console.error('Error getting user country:', error);
			}
		};

		getUserCountry();

		// set the token and merchant
		setToken(token);
		setMerchant(merchant);
	}, []);

	const handleChange = (event) => {
		// // console.log('Event:', event.target.id);
		switch (event.target.id) {
			case 'phoneNumber':
				setPhoneNumber(event.target.value);
				break;
			case 'amount':
				setAmount(event.target.value);
				break;
			default:
				break;
		}
	};

	// // console.log('Phone Number:', phoneNumber);
	// // console.log('Amount:', amount);

	const handleSubmit = async (e) => {
		// console.log('Submitting Load Card information');
		e.preventDefault();
		// handle errors
		if (phoneNumber === '' || amount === '' || countryCode === '') {
			toast.error('Please enter all fields');

			return;
		}

		if (amount < 0.1 || amount > 100) {
			toast.error('Amount must be between 0.1 and 100');
			return;
		}

		toast.info('Loading Card...');

		// delete the first character of the phone number if it starts with 0 and delete all the spaces
		let number = `${phoneNumber}`.replace(/\s/g, '');

		if (number.startsWith('0')) {
			number = number.substring(1);
		}

		// complete the phone number

		number = `${countryCode}${number}`;

		// console.log('Submitting Load Card information');

		// create the body
		const body = {
			userNumber: number,
			amount: parseFloat(amount),
			issuerNumber: merchant.phoneNumber,
			currency: 'USD',
		};

		// console.log('Body:', body);

		// post the data to the API
		const results = await APIPost('cards/load', body, token);

		// console.log('Results:', results);

		// handle the response
		if (!results.success) {
			toast.error(results.message);

			return;
		}

		toast.success('Card Loaded Successfully');

		// clear the form
		setPhoneNumber('');
		setAmount('');

		await setTimeout(() => {
			// reload the page
			window.location.reload();
		}, 2000);
	};

	return (
		<div
			className='d-flex justify-content-center align-items-center'
			style={{ height: '100vh' }}
		>
			<Notify />
			<div className='content'>
				<div className='container-fluid'>
					<div className='col-lg-12'>
						<div className='card'>
							<div className='card-body'>
								<h4 className='header-title'>Load Card</h4>
								<p className='sub-header'>
									Enter the cellphone number and amount to be loaded onto the card. <br />
									This can only be performed if the cellphone number has already been registered on the platform by the
									owner.
								</p>

								<form
									className='needs-validation'
									noValidate
								>
									<div className='mb-3 d-flex'>
										<select
											className='form-select'
											id='countryCode'
											value={countryCode}
											onChange={(e) => setCountryCode(e.target.value)}
											style={{ width: '35%' }}
										>
											<option value=''>Country Code</option>
											<option value='+27'>South Africa (+27)</option>
											<option value='+263'>Zimbabwe (+263)</option>
											<option value='+264'>Namibia (+264)</option>
											<option value='+260'>Zambia (+260)</option>
											<option value='+267'>Botswana (+267)</option>
											<option value='+266'>Lesotho (+266)</option>
											<option value='+268'>Swaziland (+268)</option>
										</select>
										<input
											type='text'
											className='form-control'
											id='phoneNumber'
											placeholder='Enter Phone Number Here'
											value={phoneNumber}
											required
											onChange={(e) => handleChange(e)}
										/>

										<div className='valid-feedback'>Looks good!</div>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='validationCustom02'
											className='form-label'
										>
											Amount (between 0.10 and 100)
										</label>
										<input
											type='number'
											className='form-control'
											id='amount'
											placeholder='Enter Amount Here (0.00)'
											value={amount}
											min={0.1}
											max={100}
											required
											onChange={(e) => handleChange(e)}
										/>
										<div className='valid-feedback'>Looks good!</div>
									</div>
									<button
										className='btn btn-primary'
										type='submit'
										onClick={(e) => handleSubmit(e)}
									>
										Load Card
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoadCard;
