import React, { useState, useEffect } from 'react';
import { APIPost } from '../utils/apicore';
import axios from 'axios';
import Notify from '../components/global/notify';
import { toast } from 'react-toastify';

const RedeemCard = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [redemptionCode, setRedemptionCode] = useState('');
	const [requested, setRequested] = useState(false);
	const [amount, setAmount] = useState(0.0);
	const [token, setToken] = useState('');
	const [merchant, setMerchant] = useState({});

	useEffect(() => {
		// get the token from local storage
		const token = localStorage.getItem('token');

		// get the merchant from local storage
		const merchant = JSON.parse(localStorage.getItem('merchant'));

		// if token is not found, redirect to login
		if (!token || token === '' || token === null || !merchant) {
			window.location.href = '/#/login';
		}

		// get the user's country location
		const getUserCountry = async () => {
			console.log(`API Key: ${process.env.REACT_APP_IP_APIKEY}`);
			try {
				const response = await axios.get(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IP_APIKEY}`);

				// set the user's country code
				setCountryCode(`+${response.data.calling_code}`);
				console.log(response.data);
				console.log('Calling Code:', response.data.calling_code);
			} catch (error) {
				console.error('Error getting user country:', error);
			}
		};

		// getUserCountry();

		// set the token and merchant
		setToken(token);
		setMerchant(merchant);
	}, []);

	const handleChange = (event) => {
		console.log('Event:', event.target.id);
		switch (event.target.id) {
			case 'phoneNumber':
				setPhoneNumber(event.target.value);
				break;
			case 'redemptionCode':
				setRedemptionCode(event.target.value);
				break;
			case 'amount':
				setAmount(event.target.value);
				break;
			default:
				break;
		}
	};

	// console.log('Phone Number:', redemptionCode);
	// console.log('Amount:', amount);

	const handleRequest = async (e) => {
		console.log('Requested Code');
		e.preventDefault();
		// handle errors
		if (phoneNumber === '' || amount === '' || countryCode === '') {
			toast.error('Please enter all fields');
			return;
		}

		console.log('Submitting Load Card information');

		// create the body
		const body = {
			userNumber: phoneNumber,
			amount: parseFloat(amount),
			issuerNumber: merchant.phoneNumber,
		};

		console.log('Body:', body);

		// post the data to the API
		const results = await APIPost('cards/request', body, token);

		console.log('Results:', results);

		// handle the response
		if (!results.success || results.error) {
			toast.dismiss();
			toast.error(results.message);

			return;
		}

		toast.dismiss();
		toast.success('Enter Redemption Code sent to the user phone number');
		setRequested(true);
	};

	const handleConfirmTransaction = async (e) => {
		e.preventDefault();
		// handle errors
		if (redemptionCode === '' || amount === '' || phoneNumber === '') {
			toast.dismiss();
			toast.error('Please enter all fields');
			return;
		}

		console.log('Submitting Load Card information');

		// create the body
		const body = {
			userNumber: phoneNumber,
			amount: parseFloat(amount),
			issuerNumber: merchant.phoneNumber,
			redemptionCode: redemptionCode,
		};

		console.log('Body:', body);

		// post the data to the API
		const results = await APIPost('cards/redeem', body, token);

		console.log('Results:', results);

		// handle the response
		if (!results.success) {
			toast.dismiss();
			toast.error(results.message);
			return;
		}

		toast.dismiss();
		toast.success('Card Redeem Successfully');

		// clear the form
		setRedemptionCode('');
		setAmount('');

		await setTimeout(() => {
			// reload the page
			window.location.reload();
		}, 2000);
	};
	return (
		<div
			className='d-flex justify-content-center align-items-center'
			style={{ height: '100vh' }}
		>
			<Notify />
			<div className='content'>
				<div className='container-fluid'>
					<div className='col-lg-12'>
						<div className='card'>
							<div className='card-body'>
								<h4 className='header-title'>Redeem Card</h4>
								<p className='sub-header'>
									Enter the cellphone number and amount to be redeemed from the card. <br />
									This can only be performed if the cellphone number has already been registered on the platform by the
									owner.
								</p>

								<form
									className='needs-validation'
									noValidate
								>
									<div className='mb-3 d-flex'>
										<select
											className='form-select'
											id='countryCode'
											value={countryCode}
											onChange={(e) => setCountryCode(e.target.value)}
											style={{ width: '35%' }}
										>
											<option value=''>Country Code</option>
											<option value='+27'>South Africa (+27)</option>
											<option value='+263'>Zimbabwe (+263)</option>
											<option value='+264'>Namibia (+264)</option>
											<option value='+260'>Zambia (+260)</option>
											<option value='+267'>Botswana (+267)</option>
											<option value='+266'>Lesotho (+266)</option>
											<option value='+268'>Swaziland (+268)</option>
										</select>
										<input
											type='text'
											className='form-control'
											id='phoneNumber'
											placeholder='Enter Phone Number Here'
											value={phoneNumber}
											required
											onChange={(e) => handleChange(e)}
										/>

										<div className='valid-feedback'>Looks good!</div>
									</div>

									<div className='mb-3'>
										<label className='form-label'>Amount</label>
										<input
											type='text'
											className='form-control'
											id='amount'
											placeholder='Enter Amount Here'
											value={amount}
											onChange={(e) => handleChange(e)}
											required
										/>
										<div className='valid-feedback'>Looks good!</div>
									</div>
									{requested && (
										<div className='mb-3'>
											<label className='form-label'>Redemption Code</label>
											<input
												type='text'
												className='form-control'
												id='redemptionCode'
												placeholder='Enter Voucher Code Here'
												value={redemptionCode}
												onChange={(e) => handleChange(e)}
												required
											/>
											<div className='valid-feedback'>Looks good!</div>
										</div>
									)}
									<button
										className='btn btn-primary'
										type='submit'
										onClick={(e) => (!requested ? handleRequest(e) : handleConfirmTransaction(e))}
									>
										{`${!requested ? 'Start Transaction' : 'Redeem Card'}`}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RedeemCard;
