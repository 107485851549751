import React, { useState } from 'react';
import { useEffect } from 'react';
import { APIPost, APIPut } from '../utils/apicore';
import Notify from '../components/global/notify';
import { toast } from 'react-toastify';

function Login() {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [pin, setPIN] = useState('');
	const [code, setCode] = useState('');
	const [requestSuccessful, setRequestSuccessful] = useState(false);

	useEffect(() => {
		document.body.classList.add('authentication-bg', 'authentication-bg-pattern');
	}, []);

	const handlePhoneNumberChange = (e) => {
		setPhoneNumber(e.target.value);
	};

	const handleNewPinChange = (e) => {
		setPIN(e.target.value);
	};

	const handleResetCodeChange = (e) => {
		setCode(e.target.value);
	};

	const handleRequestSubmit = async (e) => {
		e.preventDefault();
		// Add your login logic here
		//console.log('Submitting');

		const results = await APIPost(`auth/user/forgotPIN/${phoneNumber}`);
		//console.log('Results:', results);
		if (!results.success) {
			toast.dismiss();
			toast.error(results.message);
			return;
		}

		toast.dismiss();
		toast.success('Check your email for the reset link');
		setRequestSuccessful(true);
	};

	const handleNewPINSubmit = async (e) => {
		e.preventDefault();

		const body = {
			pin: pin,
		};

		const results = await APIPut(`auth/user/resetPIN/${code}`, body);
		//console.log('Results:', results);
		if (!results.success) {
			toast.dismiss();
			toast.error(results.message);

			return;
		}

		toast.dismiss();
		toast.success('PIN reset successful');

		setTimeout(() => {
			window.location.href = '/#/login';
		}, 2000);
	};

	const handleSignUp = (e) => {
		e.preventDefault();
		window.location.href = '/#/signUp';
	};

	return (
		<>
			<Notify />
			<div className='account-pages my-5'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-4'>
							<div className='text-center'>
								<a href='/'>
									<img
										src='/images/Logo_Horizontal.svg'
										alt=''
										height='50'
										className='mx-auto'
									/>
								</a>
								<p className='text-muted mt-2 mb-4'>Loyalty Management System</p>
							</div>
							<div className='card'>
								<div className='card-body p-4'>
									<div className='text-center mb-4'>
										<h4 className='text-uppercase mt-0'>Forgot Pin</h4>
									</div>

									<form action='#'>
										{!requestSuccessful && (
											<div className='mb-3'>
												<label
													// htmlFor='emailaddress'
													className='form-label'
												>
													Phone Number
												</label>
												<input
													className='form-control'
													type='text'
													required=''
													placeholder='Enter your phone number (+.....)'
													onChange={(e) => handlePhoneNumberChange(e)}
												/>
											</div>
										)}

										{requestSuccessful && (
											<>
												<div className='mb-3'>
													<label
														// htmlFor='emailaddress'
														className='form-label'
													>
														Reset Code
													</label>
													<input
														className='form-control'
														type='text'
														required=''
														placeholder='Copy the code from your email'
														onChange={(e) => handleResetCodeChange(e)}
													/>
												</div>
												<div className='mb-3'>
													<label
														// htmlFor='emailaddress'
														className='form-label'
													>
														New Pin
													</label>
													<input
														className='form-control'
														type='password'
														required=''
														placeholder='Enter your new 4 digit pin'
														maxLength={4}
														onChange={(e) => handleNewPinChange(e)}
													/>
												</div>
											</>
										)}

										<div className='mb-3 d-grid text-center'>
											<button
												className='btn btn-primary'
												type='submit'
												onClick={(e) => {
													requestSuccessful ? handleNewPINSubmit(e) : handleRequestSubmit(e);
												}}
											>
												{`${requestSuccessful ? 'Change Pin' : 'Request Reset'}`}{' '}
											</button>
										</div>
									</form>
								</div>
							</div>

							<div className='row mt-3'>
								<div className='col-12 text-center'>
									<p className='text-muted'>
										Don't have an account?{' '}
										<a
											href='/#/signUp'
											className='text-dark ms-1'
										>
											<b onClick={(e) => handleSignUp(e)}>Sign Up</b>
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;
