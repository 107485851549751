import axios from 'axios';

// get API location from .env
const API = process.env.REACT_APP_API_URL;

// generate options for API calls
const options = (token) => {
	return {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Origin': `${API}`,
		},
	};
};

// post to API
export const APIPost = async (route, body, token) => {
	// const response = await axios.post(`${API}/${route}/`, body, options(token));
	// // console.log('APIPost:');
	// // console.log('APIPost: ', body);

	try {
		const response = await axios.post(`${API}/${route}/`, body, options(token));
		// console.log(`APIpost Response:`);
		// console.log(response);
		return response.data;
	} catch (error) {
		if (error.code === 'ERR_NETWORK') {
			// console.log('Connection refused');
			const response = { error: 'Network error. Check your connection and wait before trying again' };
			return response;
		} else {
			// console.log(`This is the error: `);
			// console.log(error);
			const response = handleErrors(error);
			return response;
		}
	}
};

// delete to API
export const APIDelete = (route, body, token) => {
	// // console.log('Options ', options(token));
	return axios
		.delete(`${API}/${route}/`, body, options(token))
		.then((response) => {
			// console.log(`APIpost Response:`);
			// console.log(response);
			return response.data;
		})
		.catch((error) => {
			if (error.code === 'ERR_NETWORK') {
				// console.log('Connection refused');
				const response = { error: 'Network error. Check your connection and wait before trying again' };
				return response;
			} else {
				// console.log(`This is the error: `);
				// console.log(error);
				const response = handleErrors(error);
				return response;
			}
		});
};

// get from API
export const APIGet = (route, filter, token) => {
	return axios
		.get(`${API}/${route}?${filter}`, options(token))
		.then((response) => {
			// // console.log(`APIget Response: ${JSON.stringify(response)}`);
			return response.data;
		})
		.catch((error) => {
			if (error.code === 'ERR_NETWORK') {
				// console.log('Connection refused');
				const response = { error: 'Network error. Check your connection and wait before trying again' };
				return response;
			} else {
				// console.log(`This is the error: `);
				// console.log(error);
				const response = handleErrors(error);
				return response;
			}
		});
};

export const APIPut = async (route, body, token) => {
	try {
		const response = await axios.put(`${API}/${route}/`, body, options(token));
		// console.log(`APIpost Response:`);
		// console.log(response);
		return response.data;
	} catch (error) {
		if (error.code === 'ERR_NETWORK') {
			// console.log('Connection refused');
			const response = { error: 'Network error. Check your connection and wait before trying again' };
			return response;
		} else {
			// console.log(`This is the error: `);
			// console.log(error);
			const response = handleErrors(error);
			return response;
		}
	}
};

const handleErrors = (error) => {
	let response;
	// console.log('Error: ', error.response.data.error);
	if (error.message === 'AxiosError: Network Error') {
		// console.log('API request failed');
		response = { error: 'API offline' };
		return response;
	}
	if (error.message === 'Network Error') {
		// console.log('Error is Network');
		response = { error: 'Network Error' };
		return error;
	}
	if (error.response.status === 500) {
		// console.log('Error 500');

		response = { error: error.response.data.error };
		return response;
	}
	if (error.response.status === 400) {
		// console.log('Error 400');
		response = { error: error.response.data.error };
		return response;
	}
	if (error.response.status === 401) {
		// console.log('Error 401');
		response = { error: error.response.data.error };

		return response;
	}
	if (error.response.status === 404) {
		// console.log('Error 404');
		response = { error: error.response.data.error };
		return response;
	}
	return response;
};
