import { useEffect, useState } from 'react';
import { APIGet, APIPut } from '../utils/apicore';
import Notify from './global/notify';
import { toast } from 'react-toastify';

const Dashboard = () => {
	const [token, setToken] = useState('');
	const [merchants, setMerchants] = useState({});
	const [users, setUsers] = useState({});
	const [user, setUser] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [todayTransactions, setTodayTransactions] = useState([]);
	const [todayIssuedCards, setTodayIssuedCards] = useState([]);
	const [yesterdayIssuedCards, setYesterdayIssuedCards] = useState([]);
	const [cards, setCards] = useState([]);
	// const [searchDetails, setSearchDetails] = useState('');
	// const [searchType, setSearchType] = useState('voucherCode');
	const [activeTab, setActiveTab] = useState('transactions');
	const [showModal, setShowModal] = useState(false);
	const [reportMode, setReportMode] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [searchMerchantNumber, setSearchMerchantNumber] = useState('');

	const dateStructure = (date) => {
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		const yyyy = today.getFullYear();
		return `${yyyy}-${mm}-${dd}`;
	};

	// filter transactions for today
	const today = new Date();
	const todayDate = dateStructure(today);

	//  yesterday date
	const yesterday = today.setDate(today.getDate() - 1);
	const yesterdayDate = dateStructure(yesterday);

	useEffect(() => {
		try {
			// toast.info('Loading. Please wait...');
			setToken(localStorage.getItem('adm_token'));
			setUser(JSON.parse(localStorage.getItem('user')));
			let err = false;

			if (token) {
				setToken(token);

				if (!loaded) {
					APIGet('transactions/', 'sort=-createdAt', token)
						.then((response) => {
							// console.log('Response Error: ', response.message);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
								return;
							}

							if (response.message === 'Network Error') {
								err = true;
								return;
							}
							if (response.data) {
								setTransactions(response.data);
							}
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
							err = true;
							// console.log();
						});
					// get cards
					APIGet('cards/', '', token)
						.then((response) => {
							// console.log('Response Error: ', response.error);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
								return;
							}

							if (response.message === 'Network Error') {
								err = true;
								return;
							}

							if (response.data) {
								setCards(response.data);
							}
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
							err = true;
						});
					APIGet('users/', 'sort=-createdAt', token)
						.then((response) => {
							// console.log('Response Error: ', response);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
								return;
							}

							if (response.message === 'Network Error') {
								err = true;
								return;
							}

							if (response.data) setUsers(response.data);
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
							// console.log();
						});
					// get merchants
					APIGet('merchants/', '', token)
						.then((response) => {
							// console.log('Response Error: ', response);
							if (response.error) {
								toast.dismiss();
								toast.error(response.error);
								return;
							}

							if (response.message === 'Network Error') {
								err = true;
								return;
							}

							if (response.data) setMerchants(response.data);
						})
						.catch((error) => {
							// console.log(`Error: ${error}`);
							err = true;
						});
					setLoaded(true);
				}
				// if (loaded) {
				// 	setTimeout(() => {
				// 		toast.dismiss();
				// 		toast.success('Data Loaded');
				// 	}, 1000);
				// }
			}

			if (err) {
				toast.dismiss();
				toast.error('Network Error. Please check your internet connection and try again.');
			}

			// if (merchant) {
			// 	setMerchant(merchant);
			// }
		} catch (error) {
			// console.log(`Error: ${error}`);

			// if cant find token or merchant, redirect to login
			window.location.href = '/#/login';
		}
		const tc = cards.filter((card) => {
			const cardDate = new Date(card.createdAt).toISOString().split('T')[0];
			return cardDate === todayDate;
		});

		// filter by those who
		setTodayIssuedCards(tc);
		const tt = transactions.filter((transaction) => {
			const transactionDate = new Date(transaction.createdAt).toISOString().split('T')[0];
			// console.log('Transaction Date:', transactionDate);
			// console.log('Today Date:', todayDate);
			return transactionDate === todayDate;
		});

		const yc = cards.filter((card) => {
			const cardDate = new Date(card.createdAt).toISOString().split('T')[0];
			return cardDate === yesterdayDate;
		});
		setYesterdayIssuedCards(yc);
		setTodayTransactions(tt);
	}, [cards, loaded, merchants, todayDate, token, transactions, yesterdayDate]);

	// const handleChange = (event) => {
	// console.log('Event:', event.target.id);
	// 	switch (event.target.id) {
	// 		case 'searchType':
	// 			setSearchType(event.target.value);
	// 			break;
	// 		case 'searchDetails':
	// 			setSearchDetails(event.target.value);
	// 			break;
	// 		case 'activeTab':
	// 			setActiveTab(event.target.value);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	// const handleSearch = async (e) => {
	// 	e.preventDefault();

	// 	if (searchDetails === '') {
	// 		toast.error('Please enter search details');
	// 		return;
	// 	}

	// 	// form the search query
	// 	let query = '';
	// 	switch (searchType) {
	// 		case 'phoneNumber':
	// 			query = `phoneNumber=${searchDetails}`;
	// 			break;
	// 		case 'transactionCode':
	// 			query = `transactionCode=${searchDetails}`;
	// 			break;

	// 		default:
	// 			query = `voucherCode=${searchDetails}`;
	// 			break;
	// 	}

	// 	// search for the transaction
	// 	APIGet(
	// 		`${activeTab === 'transactions' ? 'transactions' : 'cards'}/merchant`,
	// 		`sort=-createdAt&${query !== '' ? `${query}` : ''}`,
	// 		token
	// 	)
	// 		.then((response) => {
	// console.log('Response: ', response);
	// 			if (response.error) {
	// 				toast.dismiss();
	// 				toast.error(response.error);
	// 			}

	// console.log('Response: ', response.data);
	// 			if (activeTab === 'transactions') setTransactions(response.data);
	// 			else setCards(response.data);
	// 		})
	// 		.catch((error) => {
	// console.log(`Error: ${error}`);
	console.log();
	// 		});
	// };

	const cardToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Phone Number', 'Card', 'Amount', 'Created At', 'Last Transaction', 'Status'];
		// Map data to CSV rows
		try {
			const rows = data.map((card, index) => [
				index + 1,
				card.user?.phoneNumber || 'N/A',
				card.voucherCode || '_',
				`${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(
					card.amount.toString().replace(',', '') ?? 0
				)}`,
				card.createdAt ? card.createdAt : 'Unknown Date',
				card.updatedAt ? card.updatedAt : 'Unknown Date',
				card.status || 'Unknown Status',
			]);
			// Combine headers and rows, and join each row with commas
			const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
			return csvContent;
		} catch (error) {
			toast.dismiss();
			console.log('Error:', error);
			toast.error('Error creating report, try again later');
		}
	};

	const transToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Phone Number', 'Card', 'Date', 'Amount', 'Type', 'Transaction Code'];
		// Map data to CSV rows

		// console.log('Data:', data);

		try {
			const rows = data.map((transaction, index) => [
				index + 1,
				transaction.user?.phoneNumber || 'N/A',
				transaction.voucherCode || '_',
				transaction.createdAt ? transaction.createdAt : 'Unknown Date',
				transaction.amount.toString().replace(',', '') || '',
				transaction.transactionType || 'Unknown Type',
				transaction.transactionCode || 'N/A',
			]);
			// Combine headers and rows, and join each row with commas
			const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
			return csvContent;
		} catch (error) {
			toast.dismiss();
			toast.error('Error creating report, try again later');
		}
	};

	const userToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Phone Number', 'Name', 'Email', 'ID Number', 'Created', 'Status'];
		// Map data to CSV rows

		try {
			const rows = users.map((user, index) => [
				index + 1,
				user.phoneNumber || 'Unknown',
				user.profile?.name || '_',
				user.email || 'N/A',
				user.profile.id_number || 'N/A',
				user.createdAt ? user.createdAt : 'Unknown Date',
				user.kyc.verified ? 'verified' : 'not verified' || 'Unknown Status',
			]);
			// Combine headers and rows, and join each row with commas
			const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
			return csvContent;
		} catch (error) {
			toast.dismiss();
			toast.error('Error creating report, try again later');
		}
	};

	const merchantToCSV = (data) => {
		// Create CSV headers
		const headers = ['#', 'Name', 'Type', 'Phone Number', 'Email', 'Created At', 'Status'];
		// Map data to table rows
		try {
			const rows = merchants.map((merchant, index) => [
				index + 1,
				merchant.bus_name || 'N/A',
				merchant.bus_type || 'N/A',
				merchant.address.replace(/,/g, '') || 'N/A',
				merchant.phoneNumber || 'N/A',
				merchant.email || 'N/A',
				merchant.createdAt || 'Unknown Date',
				merchant.kyc.verified ? 'verified' : 'not verified' || 'Unknown Status',
			]);
			// Combine headers and rows, and join each row with commas
			const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');
			return csvContent;
		} catch (error) {
			toast.dismiss();
			toast.error('Error creating report, try again later');
		}
	};

	const downloadCSV = (csvContent, fileName = 'data.csv') => {
		// Create a Blob with the CSV content
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		// Create a link to download the Blob
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDownloadReport = async (e) => {
		e.preventDefault();

		toast.dismiss();
		toast.info('Creating Reports, Please wait...');

		let query = '';

		if (startDate !== '') {
			query = `createdAt[gte]=${startDate}`;
		}

		if (endDate !== '') {
			query += `createdAt[lte]=${endDate}`;
		}

		if (searchMerchantNumber !== '' && reportMode === 'transactions') {
			query += `phoneNumber=${searchMerchantNumber}`;
		}

		// Get all transactions from database
		if (reportMode === 'transactions') {
			handleDownloadTransactions(query);
		}

		// Get all cards from database
		if (reportMode === 'cards') {
			handleDownloadCards(query);
		}

		// Get all users from database
		if (reportMode === 'users') {
			handleDownloadUsers(query);
		}

		// Get all merchants from database
		if (reportMode === 'merchants') {
			handleDownloadMerchants(query);
		}

		// Get all cards and transactions from database
		if (reportMode === '') {
			handleDownloadTransactions(query);
			handleDownloadCards(query);
			handleDownloadUsers(query);
			handleDownloadMerchants(query);
		}
	};

	const handleDownloadTransactions = async (query) => {
		const transactions = await APIGet('transactions/merchant', `limit=1000000&${query}`, token);
		console.log('Transactions:', transactions);
		if (transactions.data.length > 0) {
			const csvContent = transToCSV(transactions.data);
			downloadCSV(csvContent, 'TransactionsData.csv');

			toast.success('Transactions Report Created Successfully');
		} else {
			toast.dismiss();
			toast.error('Could not create report!');
		}
		return;
	};

	const handleDownloadCards = async (query) => {
		const cards = await APIGet('cards/merchant', `limit=1000000&${query}`, token);
		console.log('Cards:', cards);
		if (cards.data.length > 0) {
			const csvContent = cardToCSV(cards.data);
			downloadCSV(csvContent, 'CardsData.csv');

			toast.success('Cards Report Created Successfully');
		} else {
			toast.dismiss();
			toast.error('Could not create report!');
		}
		return;
	};

	const handleDownloadUsers = async (query) => {
		const users = await APIGet('users', `limit=1000000&${query}`, token);
		if (users.data.length > 0) {
			const csvContent = userToCSV(users.data);
			downloadCSV(csvContent, 'UsersData.csv');

			toast.success('Users Report Created Successfully');
		} else {
			toast.dismiss();
			toast.error('Could not create report!');
		}
		return;
	};

	const handleDownloadMerchants = async (query) => {
		const merchants = await APIGet('merchants', `limit=1000000&${query}`, token);
		if (merchants.data.length > 0) {
			const csvContent = merchantToCSV(merchants.data);
			downloadCSV(csvContent, 'MerchantsData.csv');
			toast.success('Merchants Report Created Successfully');
		} else {
			toast.dismiss();
			toast.error('Could not create report!');
		}
		return;
	};

	const handleVerification = async (e, id, status) => {
		e.preventDefault();

		let body = {
			kyc: {
				verified: status,
			},
		};

		const results = await APIPut(`auth/${activeTab === 'merchants' ? 'merchant' : 'user'}/verify/${id}`, body, token);

		if (!results.success) {
			toast.dismiss();
			toast.error(results.message);
			return;
		}

		toast.dismiss();
		toast.success('Verification Status Changed!');

		// refresh the page
		setTimeout(() => window.location.reload(), 2000);
	};

	let cardsIncrease = 0;
	if (yesterdayIssuedCards.length !== 0)
		cardsIncrease = ((todayIssuedCards.length - yesterdayIssuedCards.length) / yesterdayIssuedCards.length) * 100;

	// check the card load from today and compare to card loads from yesterday
	const cly = todayTransactions.filter((transaction) => transaction.transactionType === 'CardLoad');
	let percentofTransactions = 0;
	if (todayTransactions.length !== 0) percentofTransactions = (cly.length / todayTransactions.length) * 100;

	// console.log(cards);
	// console.log(transactions);
	// console.log(merchants);

	return (
		<>
			<Notify />
			{showModal && (
				<div
					className={`modal fade show`}
					id='centermodal'
					tabIndex='-1'
					style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
					aria-modal='true'
					role='dialog'
				>
					<div className='modal-dialog modal-dialog-centered'>
						<div className='modal-content'>
							<div className='modal-header mb-3'>
								<h4
									className='modal-title'
									id='myCenterModalLabel'
								>
									Report Details
								</h4>
								{/* <p className='mt-3'>Enter the details of the report you want</p> */}
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
									onClick={() => setShowModal(false)}
								></button>
							</div>
							<form
								className='px-3'
								action='#'
							>
								<div className='mb-3'>
									<label className='form-label'>Type</label>
									<select
										className='form-select'
										id='reportMode'
										value={reportMode}
										onChange={(e) => setReportMode(e.target.value)}
									>
										<option value=''>All Reports</option>
										<option value='transactions'>Transactions</option>
										<option value='cards'>Cards</option>
										<option value='users'>Users</option>
										<option value='merchants'>Merchants</option>
									</select>
								</div>

								{reportMode === 'transactions' && (
									<div className='mb-3'>
										<label className='form-label'>Merchant Number</label>
										<input
											className='form-control'
											type='text'
											id='searchMerchantNumber'
											value={searchMerchantNumber}
											placeholder='Enter Merchant Phone Number (+263...) with no spaces'
											onChange={(e) => setSearchMerchantNumber(e.target.value)}
										/>
									</div>
								)}

								<div className='mb-3'>
									<label className='form-label'>From</label>
									<input
										className='form-control'
										type='date'
										id='startDate'
										placeholder='Pick Start Date'
										onChange={(e) => setStartDate(e.target.value)}
									/>
								</div>

								<div className='mb-3'>
									<label className='form-label'>To</label>
									<input
										className='form-control'
										type='date'
										id='endDate'
										placeholder='Pick End Date'
										onChange={(e) => setEndDate(e.target.value)}
									/>
								</div>

								<div className='mb-3 text-center'>
									<button
										className='btn btn-primary'
										type='submit'
										onClick={(e) => handleDownloadReport(e)}
									>
										Download Report
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
			<div className='content-page'>
				<div className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-12'>
								<div className='page-title-box'>
									<h4 className='page-title'>Admin Dashboard</h4>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-4 col-md-6'>
								<div
									className='card'
									// style={{ opacity: loaded ? 0 : 1, transition: 'opacity 1s' }}
								>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-4'>Transactions Today</h4>

										<div className='widget-chart-1'>
											<div
												className='widget-chart-box-1 float-start'
												dir='ltr'
											></div>

											<div className='widget-detail-1 text-end'>
												<h2 className='fw-normal pt-2 mb-1'>{todayTransactions.length}</h2>
												<p className='text-muted mb-1'>Today</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 col-md-6'>
								<div
									className='card'
									// style={{ opacity: loaded ? 0 : 1, transition: 'opacity 1s' }}
								>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-3'>Cards Issued Today</h4>

										<div className='widget-box-2'>
											<div className='widget-detail-2 text-end'>
												<span
													className={`badge ${
														cardsIncrease > 0 ? 'bg-success' : 'bg-danger'
													} rounded-pill float-start mt-3`}
												>
													{cardsIncrease.toFixed(0)}{' '}
													<i className={`mdi ${cardsIncrease > 0 ? 'mdi-trending-up' : 'mdi-trending-down'}`}></i>
												</span>
												<h2 className='fw-normal mb-1'>{todayIssuedCards.length}</h2>
												<p className='text-muted mb-3'>Cards Issued</p>
											</div>
											<div
												className={`progress ${
													cardsIncrease > 0 ? 'progress-bar-alt-success' : 'progress-bar-alt-danger'
												} progress-sm`}
											>
												<div
													className='progress-bar bg-success'
													role='progressbar'
													aria-valuenow='1'
													aria-valuemin='0'
													aria-valuemax='100'
													style={{ width: `${Math.abs(cardsIncrease)}` }}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-xl-4 col-md-6'>
								<div className='card'>
									<div className='card-body'>
										<h4 className='header-title mt-0 mb-3'>Card Loads Today</h4>

										<div className='widget-box-2'>
											<div className='widget-detail-2 text-end'>
												<span
													className={`badge ${
														percentofTransactions > 0 ? 'bg-success' : 'bg-danger'
													} rounded-pill float-start mt-3`}
												>
													{percentofTransactions.toFixed(0)}
													<i
														className={`mdi ${percentofTransactions > 0 ? 'mdi-trending-up' : 'mdi-trending-down'}`}
													></i>
												</span>
												<h2 className='fw-normal mb-1'>{cly.length}</h2>
												<p className='text-muted mb-3'>Overall Transactions</p>
											</div>
											<div
												className={`progress ${
													percentofTransactions > 0 ? 'progress-bar-alt-success' : 'progress-bar-alt-danger'
												} progress-sm`}
											>
												<div
													className='progress-bar bg-danger'
													role='progressbar'
													aria-valuenow='20'
													aria-valuemin='0'
													aria-valuemax='100'
													style={{ width: `${Math.abs(percentofTransactions)}` }}
												></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='mb-3 row'>
							<div className='col-6'>
								<div className='input-group'>
									<button
										className={`btn waves-effect waves-light col-3 ${
											activeTab === 'transactions' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('transactions')}
									>
										Transactions
									</button>

									<button
										className={`btn waves-effect waves-light col-3 ${
											activeTab === 'cards' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('cards')}
									>
										Cards
									</button>
									<button
										className={`btn waves-effect waves-light col-3 ${
											activeTab === 'merchants' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('merchants')}
									>
										Merchants
									</button>

									<button
										className={`btn waves-effect waves-light col-3 ${
											activeTab === 'users' ? 'btn-primary' : 'btn-outline-primary'
										}`}
										type='button'
										onClick={() => setActiveTab('users')}
									>
										Customers
									</button>
								</div>
							</div>
							<div className='col-4'>
								{/* <label className='form-label'>Buttons</label> */}
								{/* <div className='input-group'>
									{activeTab === 'transactions' && (
										<input
											type='text'
											id='searchDetails'
											className='form-control col-6'
											placeholder={`Enter loyalty card number`}
											onChange={handleChange}
										/>
									)}
									{activeTab === 'cards' && (
										<input
											type='text'
											id='searchDetails'
											className='form-control col-6'
											placeholder={`Enter loyalty card number`}
											onChange={handleChange}
										/>
									)}
									{activeTab === 'users' && (
										<input
											type='text'
											id='searchDetails'
											className='form-control col-6'
											placeholder={`Enter phone number`}
											onChange={handleChange}
										/>
									)}
									{activeTab === 'merchants' && (
										<input
											type='text'
											id='searchDetails'
											className='form-control col-6'
											placeholder={`Enter phone number`}
											onChange={handleChange}
										/>
									)}
									<button
										className='btn input-group-text btn-soft-primary waves-effect waves-light col-3'
										type='button'
										onClick={handleSearch}
									>
										Search
									</button>
								</div> */}
							</div>
							<div className='col-2'>
								<button
									className='btn btn-primary waves-effect waves-light col-12'
									onClick={() => setShowModal(true)}
								>
									{' '}
									Download Full Report
								</button>
							</div>
						</div>

						<div className='row'>
							<div className='col-xl-12'>
								{activeTab === 'transactions' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{transactions.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Phone Number</th>
																<th>Card</th>
																<th>Date</th>
																<th>Amount</th>
																<th>Type</th>
																<th>Transaction Code</th>
															</tr>
														</thead>
														<tbody>
															{transactions.map((transaction, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{transaction.user?.phoneNumber || 'N/A'}</td>
																	<td>{transaction.voucherCode || '_'}</td>
																	<td>
																		{transaction.createdAt
																			? new Date(transaction.createdAt).toLocaleString()
																			: 'Unknown Date'}
																	</td>
																	<td>
																		{transaction.currency}
																		{new Intl.NumberFormat('en-US', {
																			style: 'decimal',
																			minimumFractionDigits: 2,
																		}).format(transaction.amount ?? 0)}
																	</td>
																	<td>
																		<span
																			className={`badge ${
																				transaction.transactionType === 'CardLoad' ? 'bg-success' : 'bg-info'
																			}`}
																		>
																			{transaction.transactionType || 'Unknown Type'}
																		</span>
																	</td>
																	<td>{transaction.transactionCode || 'N/A'}</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								{activeTab === 'cards' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{cards.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Phone Number</th>
																<th>Card</th>
																<th>Amount</th>
																<th>Created At</th>
																<th>Last Transaction</th>
																<th>Status</th>
															</tr>
														</thead>
														<tbody>
															{cards.map((card, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{card.user?.phoneNumber || 'N/A'}</td>
																	<td>{card.voucherCode || '_'}</td>
																	<td>
																		{card.currency}
																		{new Intl.NumberFormat('en-US', {
																			style: 'decimal',
																			minimumFractionDigits: 2,
																		}).format(card.amount ?? 0)}
																	</td>
																	<td>{card.createdAt ? new Date(card.createdAt).toLocaleString() : 'Unknown Date'}</td>
																	<td>{card.updatedAt ? new Date(card.updatedAt).toLocaleString() : 'Unknown Date'}</td>
																	<td>
																		<span className={`badge ${card.status === 'Active' ? 'bg-success' : 'bg-info'}`}>
																			{card.status || 'Unknown Status'}
																		</span>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								{activeTab === 'users' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{users.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Phone Number</th>
																<th>Name</th>
																<th>Email</th>
																<th>ID Number</th>
																<th>Created</th>
																<th>Status</th>
																<></>
															</tr>
														</thead>
														<tbody>
															{users.map((user, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{user.phoneNumber || 'Uknown'}</td>
																	<td>{user.profile?.name || '_'}</td>
																	<td>{user.email || 'N/A'}</td>
																	<td>{user.profile.id_number || 'N/A'}</td>
																	<td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : 'Unknown Date'}</td>

																	{/* <td>
																		<span className={`badge ${user.kyc.verified ? 'bg-success' : 'bg-danger'}`}>
																			{user.kyc.verified ? 'verified' : 'not verified' || 'Unknown Status'}
																		</span>
																	</td> */}
																	<td>
																		<button
																			className={`btn btn-sm ${user.kyc.verified ? 'btn-success' : 'btn-danger'}`}
																			onClick={(e) => handleVerification(e, user.phoneNumber, !user.kyc.verified)}
																		>
																			{user.kyc.verified ? 'Active' : 'Deactivated'}
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								{activeTab === 'merchants' && (
									<div className='card'>
										<div className='card-body'>
											<div className='table-responsive'>
												{merchants.length > 0 ? (
													<table className='table table-hover mb-0'>
														<thead>
															<tr>
																<th>#</th>
																<th>Name</th>
																<th>Type</th>
																<th>Address</th>
																<th>Phone Number</th>
																<th>Email</th>
																<th>Created At</th>
																<th>Status</th>
																{/* <th></th> */}
															</tr>
														</thead>
														<tbody>
															{merchants.map((merchant, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{merchant.bus_name || 'N/A'}</td>
																	<td>{merchant.bus_type || 'N/A'}</td>
																	<td>{merchant.address || 'N/A'}</td>
																	<td>{merchant.phoneNumber || 'N/A'}</td>
																	<td>{merchant.email || 'N/A'}</td>
																	<td>
																		{merchant.createdAt
																			? new Date(merchant.createdAt).toLocaleString()
																			: 'Unknown Date'}
																	</td>
																	{/* <td>
																		<span className={`badge ${merchant.kyc.verified ? 'bg-success' : 'bg-danger'}`}>
																			{merchant.kyc.verified ? 'verified' : 'not verified' || 'Unknown Status'}
																		</span>
																	</td> */}
																	<td>
																		<button
																			className={`btn btn-sm ${merchant.kyc.verified ? 'btn-success' : 'btn-danger'}`}
																			onClick={(e) =>
																				handleVerification(e, merchant.phoneNumber, !merchant.kyc.verified)
																			}
																		>
																			{merchant.kyc.verified ? 'Active' : 'Deactivated'}
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												) : (
													<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<p style={{ fontSize: '25px' }}>{`${loaded ? 'Nothing to show' : 'Loading...'}`}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className='mb-3 d-flex justify-content-center'></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
